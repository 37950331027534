@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
body {
  margin: 0;
  font-family: 'Rubik Mono One', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: orange;
}

a {
  text-decoration: none;
}

code {
  font-family: 'Rubik Mono One', sans-serif;
}

.Navbar a {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 5px 20px;

}

.bigblue {
  color: DodgerBlue;
  padding: 40px;
  font-family: Arial;
  text-align: center;
}


h3 {
  font-family: 'Rubik Mono One', sans-serif;
  font-size: 46px;
  color: #000;
}

.col-lg-4 a {
  text-decoration: none;
  color: #000;
}
.skewed {
  transform: skew(2deg, 0deg);
  background-color: pink;
}
.Service {
  

  margin-top: 50px;
  padding: 1em 0;
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 1em;

box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.Service h3 {
  color: #fea000;
  padding: 10px 5px;
  font-size: 2em;

}

.Service p {
  font-family: 'Rubik Mono One', sans-serif;
  text-align: center;
  font-style: italic;
  line-height: 24px;
}

.col-lg-4 {
  font-size: 36px;
}

.col-lg-4 p {
  font-size: 18px;
font-family: 'IBM Plex Sans', sans-serif;
}

.list-group-item {
  font-family: 'Rubik Mono One', sans-serif;
  font-size: 14px;
}

.img {
  width: 100%;
}